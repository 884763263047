import React from "react";
import EventAssociationsCart from "./EventAssociationsCart";

const EventAssociations = ({ data,setRefreshEventAssociations }) => {
  // If data is undefined, return a fallback
  if (!data) {
    return <div>Loading data...</div>;
  }

  // Handle the case where there is no data
  if (data.length === 0) {
    return <div>Ecosystem data not available.</div>;
  }

  return (
    <div>
      <h1>Ecosystem</h1>
      <EventAssociationsCart
  data={data}
  setRefreshAssociations={setRefreshEventAssociations}
/>
    </div>
  );
};

export default EventAssociations;