import React from "react";
import { Card, Row, Col, Typography } from "antd";

const { Title, Paragraph } = Typography;

const EventAssociationsCart = ({ data }) => {
  return (
    <Row gutter={[16, 16]}>
      {data.map((item, index) => (
        <Col span={24} key={index}>
          <Card
            style={{
              marginBottom: "16px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <Row justify="space-between">
              <Col>
                <Title level={4}>{item.company}</Title>
              </Col>
              <Col>
                <Title level={4}>{item.associated_event}</Title>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col>
                <Paragraph>
                  Date: {item.associated_event_date || "N/A"}
                </Paragraph>
              </Col>
              <Col>
                <Paragraph>
                  Website:{" "}
                  <a
                    href={item.associated_event_website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.associated_event_website}
                  </a>
                </Paragraph>
              </Col>
            </Row>

            <Paragraph style={{ marginTop: "12px" }}>
              {item.associated_event_registration_information}
            </Paragraph>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default EventAssociationsCart;